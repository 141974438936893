export type EnvironmentConfig = {
    clientId: string
    redirectUri: string
    memberUrl: string
    promoCodesApiUrl: string
    env: string
}

export const fetchConfig = async (): Promise<EnvironmentConfig> => {
    const response = await fetch('/config.json')
    return response.json()
}
