import React from 'react'
import { createRoot } from 'react-dom/client'
import 'regenerator-runtime'
import { initAuth } from '@platform/platform-auth'
import * as serviceWorker from './serviceWorker'
import { fetchConfig } from './utils/config'
import { shouldDisplay2022Nav } from '@platform/cj-platform-navigation'

const App = React.lazy(async () => await import('./App'))
const OldApp = React.lazy(() => import('./OldNav/App'))

const fetchDepAndRender = async () => {
    const config = await fetchConfig()

    const container = document.getElementById('root')
    if (container) {
        const root = createRoot(container)
        root.render(
            <React.Suspense fallback={<></>}>
                <App config={config} />
            </React.Suspense>,
        )
    }
}
const fetchDepAndRenderOldNav = async () => {
    const config = await fetchConfig()

    await initAuth({
        clientId: config.clientId,
        redirectUri: config.redirectUri,
    })

    const container = document.getElementById('root')
    if (container) {
        const root = createRoot(container)
        root.render(
            <React.Suspense fallback={<></>}>
                <OldApp config={config} />
            </React.Suspense>,
        )
    }
}

shouldDisplay2022Nav()
    .then((shouldUseNewNav) => {
        if (shouldUseNewNav) {
            return fetchDepAndRender()
        } else {
            return fetchDepAndRenderOldNav()
        }
    })
    .catch(console.error)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
